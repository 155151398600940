<template>
  <div>
    <div :class="$style.students">
      <Title text="Ученики" position="right" />
      <div :class="$style.students__header">
        <Input
          :class="$style.students__header_input"
          placeholder="Поиск"
          @input="(value) => inputFilter(value)"
          :value="filterValue"
        />
        <div :class="$style.students__header_count">
          Общее количество пользователей: {{ overall }}
        </div>
      </div>
      <div :class="$style.table">
        <div :class="$style.table__header">
          <div :class="$style.table__header_row">
            <div
              :class="$style.table__header_column"
              v-for="headerColumn in headerColumns"
              :key="headerColumn.id"
            >
              {{ headerColumn.title }}
              <img
                v-if="headerColumn.value"
                src="@/assets/icons/arrow.svg"
                alt="arrow"
                @click="sortStudents(headerColumn.value)"
              />
            </div>
          </div>
        </div>
        <div :class="$style.table__content">
          <div
            v-for="student in students"
            :key="student.id"
            :class="$style.table__content_row"
          >
            <div :class="$style.table__content_column">
              {{ student.id }}
            </div>
            <div :class="$style.table__content_column">
              {{ student.attributes.birthday }}
            </div>
            <div :class="$style.table__content_column">
              {{ student.attributes.gender }}
            </div>
            <div :class="$style.table__content_column">
              {{
                `${student.attributes.lastName} ${student.attributes.firstName} ${student.attributes.middleName}`
              }}
            </div>
            <div :class="$style.table__content_column">
              {{ `${student.phone}` }}
            </div>
          </div>
          <div :class="$style.table__content_wrapperLine">
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
          </div>
        </div>
      </div>
    </div>
    <pagination
      :pageCount="Math.ceil(pageCount / 10)"
      :prevText="''"
      :click-handler="(page) => onStudentPageChange(page, true)"
      :nextText="''"
      :containerClass="'pagination'"
      :page-class="'page'"
      :forcePage="forcePage"
      :initial-page="$route.query.page - 1"
    />
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy'
import users from '@/api/users'
// import paginationMixin from '@/mixins/pagination.mixin'

import Title from '@/basic/Title'
import Input from '@/basic/Input'

import _ from 'lodash'
import axios from 'axios'

import { Roles } from '@/application/constants'

const headerColumns = [
  {
    id: 1,
    value: 'id',
    title: 'id'
  },
  {
    id: 2,
    value: 'attributes.birthday',
    title: 'Дата рождения '
  },
  {
    id: 3,
    value: 'attributes.gender',
    title: 'Пол'
  },
  {
    id: 4,
    value: 'attributes.lastName',
    title: 'Фио'
  },
  {
    id: 5,
    value: 'phone',
    title: 'Телефон'
  }
]

export default {
  name: 'Students',
  components: {
    Title,
    Input
  },

  data() {
    return {
      students: [],
      forcePage: 0,
      sortMethod: 'asc',
      overall: 0,
      pageCount: 0,
      paginationLoaded: true,
      filterValue: '',
      filterValueThrottle: _.throttle(this.inputValueChange, 300, { leading: false }),
      headerColumns,
      pagination: {
        limit: 10,
        offset: (this.$route.query.page - 1) * 10
      },
      order: {
        field: 'id',
        by: 'desc'
      },
      CancelToken: null,
      source: null
    }
  },
  // mixins: [paginationMixin],
  // computed: {
  //   filteredStudents() {
  //     if (this.filterValue === '') {
  //       return this.students
  //     }
  //     return this.students?.filter(
  //       (order) =>
  //         (order && order.id.toString().indexOf(this.filterValue) >= 0) ||
  //         order.attributes.birthday?.indexOf(this.filterValue.toLowerCase()) >= 0 ||
  //         order.attributes.gender?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >=
  //           0 ||
  //         order.attributes.lastName?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >=
  //           0 ||
  //         order.attributes.firstName?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >=
  //           0 ||
  //         order.attributes.middleName?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >=
  //           0
  //     )
  //   }
  // },
  async created() {
    await this.overallCount(Roles.STUDENT)
    await this.getList()
  },
  watch: {
    filterValue: {
      handler: _.debounce(async function() {
        await this.onStudentPageChange(1, false)
      }, 1000)
    }
  },
  methods: {
    async onStudentPageChange(page, isPagination = true) {
      this.forcePage = +page -1
      if (isPagination) {
        await this.$router.push(`?page=${page}`)
      }
      if(!isPagination) {
        if(this.$route.query.page !== '1') {
          await this.$router.push(`?page=1`)
        }
      }
      await this.getList()
    },
    async overallCount(roleId) {
      try {
        const { result } = await users.overallCount(roleId)
        this.overall = result
      } catch (error) {
        console.warn(error)
        this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка', message: 'Ошибка получения данных с сервера' }
        ])
      }
    },
    async getList() {
      try {
        const page = parseInt(this.$route.query.page)
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()
        const {
          data: {
            result: { count, rows }
          }
        } = await users.getStudents(
          {
            pagination: {
              offset: page * 10 - 10,
              limit: 10
            },
            order: this.order,
            scan: {
              fields:[
                'id'
              ],
              value: this.filterValue
            }
          },
          {
            cancelToken: this.source.token
          }
        )
        this.students = rows
        this.pageCount = count
      } catch (error) {
        console.warn(error)
      }
    },
    async onPageChange(page) {
      if (Number(this.$route.query.page) !== page) {
        await this.$router.push(`?page=${page}`)
      }
      this.pagination.offset = 10 * (page - 1)
      await this.getList()
    },
    inputFilter(value) {
      if (this.source) {
        this.source.cancel('[Match users operation]: CANCELED')
      }
      this.filterValueThrottle(value)
    },
    async inputValueChange(value) {
      this.filterValue = value
      this.paginationLoaded = false
      await this.onPageChange(1)
      this.paginationLoaded = true
    },
    sortStudents(sortValue) {
      this.toggleSortMethod()
      this.students = orderBy(this.students, sortValue, this.sortMethod)
    },
    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.students {
  &__header {
    display: flex;
    margin: 0 0 2rem;
    justify-content: space-between;
    &_input {
      width: 15rem;
      margin: 0 4rem 0 0;
    }
    &_count {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .table {
    width: 100%;
    height: 85%;
    border: 1px solid $table-border;

    &__header {
      &_row {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }

      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
          padding: 10px;
        }

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;

      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
        &_active {
          background: $red;
          border-right: 1px solid $table-border;
        }
      }

      &_column {
        display: flex;
        align-items: center;
        height: 2.5rem;
        padding: 0 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 7.5%;
          justify-content: center;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }

      &_wrapperLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
